// core version + navigation, pagination modules:
import Swiper, { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import { formatDistanceToNow } from 'date-fns';
import { hr } from 'date-fns/locale'

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

/* CSS */
import "@js/parts/css";

//import "./site";
import "./multiselect";


window.relativeDate = function () {
  return {

    formattedDateString: "",

    formatDistanceToNow(date, options) {
      this.formattedDateString = formatDistanceToNow(date, { ...options, locale: hr });
    }

  }
};

Fancybox.bind("[data-fancybox]", {
  // Your custom options
});



/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

// init Swiper featured articles:
const swiper = new Swiper('.swiper-featured', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,

  modules: [Navigation, Autoplay],
  autoplay: {
    delay: 5000,
  },

  on: {
    init: function () {
      const activeSlide = this.slides[0];
      const metaInfo = activeSlide.querySelector('.article-meta-info-content').outerHTML;
      document.querySelector('.featured-news--flying').innerHTML = metaInfo;
    },
    slideChange: function () {
      const activeSlide = swiper.slides[swiper.activeIndex];
      const metaInfo = activeSlide.querySelector('.article-meta-info-content').outerHTML;
      document.querySelector('.featured-news--flying').innerHTML = metaInfo;
    },
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-featured-button-next',
    prevEl: '.swiper-featured-button-prev',
  },

});

// init Swiper featured articles:
const swiperHeaderAds = new Swiper('.swiper-head-banner', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  modules: [Autoplay],
  autoplay: {
    delay: 4000,
    pauseOnMouseEnter: true
  }
});

const swiperSuperHeaderAds = new Swiper('.swiper-superhead-banner', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  modules: [Autoplay],
  autoplay: {
    delay: 4000,
    pauseOnMouseEnter: true
  }
});

const swiperRightAds = new Swiper('.swiper-right-banners', {
  // Optional parameters
  touchEventsTarget: 'wrapper',
  simulateTouch: true,
  direction: 'vertical',
  loop: true,
  modules: [Autoplay],
  slidesPerView: 4,
  spaceBetween: 15,
  autoplay: {
    delay: 4000,
    pauseOnMouseEnter: true
  }
});

const swiperInlineGallery = new Swiper('.swiper-article', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  autoplay: false,
  modules: [Navigation],

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});
